var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.user.information && _vm.loaded
    ? _c("section", [
        !_vm.meeting_enabled
          ? _c("div", [
              _c("div", { staticClass: "center-information" }, [
                _c("div", { staticClass: "center-information-content" }, [
                  _c("i", { staticClass: "fa-light fa-2x fa-video-slash" }),
                  _c("br"),
                  _c("br"),
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("NO_MEETING_SERVICE")) +
                      "\n      "
                  ),
                ]),
              ]),
            ])
          : _vm._e(),
        _vm.meeting_enabled
          ? _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.message_uuid == "",
                    expression: "message_uuid == ''",
                  },
                ],
              },
              [
                _c(
                  "b-form",
                  { staticClass: "mt-2", attrs: { inline: "" } },
                  [
                    _c("AddressBook", {
                      attrs: { user_uuid: "", functionbox_uuid: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function ({ onClick }) {
                              return [
                                _c(
                                  "b-button",
                                  {
                                    directives: [
                                      {
                                        name: "b-tooltip",
                                        rawName: "v-b-tooltip.hover",
                                        modifiers: { hover: true },
                                      },
                                    ],
                                    staticClass: "no-border",
                                    attrs: {
                                      title: _vm.$t("ADDRESSBOOK.TITLE"),
                                    },
                                    on: { click: onClick },
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "fal fa-address-book",
                                    }),
                                  ]
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        1847607087
                      ),
                    }),
                    _c(
                      "b-button",
                      {
                        staticClass: "no-border",
                        on: { click: _vm.refreshList },
                      },
                      [_c("i", { staticClass: "fal fa-sync" })]
                    ),
                    _c("AddMeeting", {
                      key: _vm.addMeetingKey,
                      attrs: { draftInformation: _vm.draftInformation },
                      on: { Close: _vm.CloseAddMeeting, Changed: _vm.Changed },
                    }),
                    _c(
                      "label",
                      {
                        staticClass: "sr-only",
                        attrs: { for: "search-meetings" },
                      },
                      [_vm._v(_vm._s(_vm.$t("SEARCH")))]
                    ),
                    _c("b-form-input", {
                      staticClass: "ml-2 i-100 hidden-mobile searchText ml-2",
                      attrs: {
                        trim: "",
                        id: "search-meetings",
                        debounce: "500",
                        placeholder: _vm.$t("SEARCH"),
                      },
                      on: {
                        keydown: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          $event.preventDefault()
                        },
                      },
                      model: {
                        value: _vm.searchText,
                        callback: function ($$v) {
                          _vm.searchText = $$v
                        },
                        expression: "searchText",
                      },
                    }),
                    _c(
                      "label",
                      {
                        staticClass: "sr-only",
                        attrs: { for: "list-limit-meetings" },
                      },
                      [_vm._v(_vm._s(_vm.$t("LIMIT")))]
                    ),
                    _c(
                      "b-form-select",
                      {
                        staticClass: "limit hidden-mobile",
                        attrs: {
                          id: "list-limit-meetings",
                          name: "meetings_limit",
                        },
                        model: {
                          value: _vm.limit,
                          callback: function ($$v) {
                            _vm.limit = $$v
                          },
                          expression: "limit",
                        },
                      },
                      [
                        _c("b-form-select-option", { attrs: { value: "10" } }, [
                          _vm._v("10"),
                        ]),
                        _c("b-form-select-option", { attrs: { value: "25" } }, [
                          _vm._v("25"),
                        ]),
                        _c("b-form-select-option", { attrs: { value: "45" } }, [
                          _vm._v("45"),
                        ]),
                        _c(
                          "b-form-select-option",
                          { attrs: { value: "100" } },
                          [_vm._v("100")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "hidden-not-mobile w-100 mt-2" },
                      [
                        _c(
                          "b-form",
                          { attrs: { inline: "" } },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "sr-only",
                                attrs: { for: "search-meetings-mobile" },
                              },
                              [_vm._v(_vm._s(_vm.$t("SEARCH")))]
                            ),
                            _c("b-form-input", {
                              staticClass: "ml-2 i-100 searchText",
                              attrs: {
                                trim: "",
                                id: "search-meetings-mobile",
                                placeholder: _vm.$t("SEARCH"),
                              },
                              on: {
                                keydown: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  )
                                    return null
                                  $event.preventDefault()
                                },
                              },
                              model: {
                                value: _vm.searchText,
                                callback: function ($$v) {
                                  _vm.searchText = $$v
                                },
                                expression: "searchText",
                              },
                            }),
                            _c(
                              "label",
                              {
                                staticClass: "sr-only",
                                attrs: {
                                  for: "inline-form-input-limit-mobile",
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("LIMIT")))]
                            ),
                            _c(
                              "b-form-select",
                              {
                                staticClass: "limit",
                                attrs: {
                                  id: "inline-form-input-limit-mobile",
                                  name: "meetings_limit_mobile",
                                },
                                model: {
                                  value: _vm.limit,
                                  callback: function ($$v) {
                                    _vm.limit = $$v
                                  },
                                  expression: "limit",
                                },
                              },
                              [
                                _c(
                                  "b-form-select-option",
                                  { attrs: { value: "10" } },
                                  [_vm._v("10")]
                                ),
                                _c(
                                  "b-form-select-option",
                                  { attrs: { value: "25" } },
                                  [_vm._v("25")]
                                ),
                                _c(
                                  "b-form-select-option",
                                  { attrs: { value: "45" } },
                                  [_vm._v("45")]
                                ),
                                _c(
                                  "b-form-select-option",
                                  { attrs: { value: "100" } },
                                  [_vm._v("100")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "b-nav",
                  { attrs: { tabs: "" } },
                  [
                    _c(
                      "b-nav-item",
                      {
                        attrs: {
                          to: {
                            name: "Meetings.Calendar",
                            params: { searchText: _vm.searchText },
                          },
                          exact: "",
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("MEETINGS.CALENDAR")))]
                    ),
                    _c(
                      "b-nav-item",
                      { attrs: { to: { name: "Meetings.Drafts" }, exact: "" } },
                      [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.$t("MESSAGES.DRAFTS")) +
                            "\n      "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _c("router-view", {
                  attrs: {
                    refresh: "router_Meetings" + _vm.keyIndex,
                    searchText: _vm.searchText,
                    update_message_uuid: _vm.update_message_uuid,
                    limit: _vm.limit,
                  },
                  on: {
                    "update:searchText": function ($event) {
                      _vm.searchText = $event
                    },
                    "update:search-text": function ($event) {
                      _vm.searchText = $event
                    },
                    changeUpdateMessageUuid: _vm.changeUpdateMessageUuid,
                    viewingMessage: _vm.viewingMessage,
                    draftMeeting: _vm.DraftMeeting,
                    "update:limit": function ($event) {
                      _vm.limit = $event
                    },
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm.message_uuid != ""
          ? _c(
              "div",
              [
                _c("Message", {
                  attrs: {
                    message_uuid: _vm.message_uuid,
                    startMeeting: _vm.startMeeting,
                    endMeeting: _vm.endMeeting,
                    functionbox_uuid: "",
                    "show-close-btn": "",
                  },
                  on: { closeMessage: _vm.closeMessage },
                }),
              ],
              1
            )
          : _vm._e(),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }