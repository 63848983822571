<template>
  <span v-if="user.information">
    <span v-if="!showMultiple && meetingEnabled">
      <b-button class="inlineBtn" variant="dark" @click="showJitsi">
        {{ $t("MEETINGS.COMPOSE") }}
      </b-button>
    </span>
    <span v-if="showMultiple && meetingEnabled">
      <b-dropdown variant="dark">
        <template #button-content>{{ $t("MEETINGS.COMPOSE") }}</template>
        <b-dropdown-item
          href="#"
          v-if="jitsi_confidential_enabled"
          @click="showJitsiConfidentially"
          ><img
            class="icon mr-2"
            :alt="$t('MESSAGES.TYPE.jitsi-secure-meeting')"
            src="/img/message_type/jitsi-secure-meeting.png"
          />
          {{ $t("MESSAGES.TYPE.jitsi-secure-meeting") }}</b-dropdown-item
        >
        <b-dropdown-item href="#" v-if="jitsi_enabled" @click="showJitsi"
          ><img
            class="icon mr-2"
            :alt="$t('MESSAGES.TYPE.jitsi-meeting')"
            src="/img/message_type/jitsi-meeting.png"
          />
          {{ $t("MESSAGES.TYPE.jitsi-meeting") }}</b-dropdown-item
        >
        <b-dropdown-item href="#" v-if="nuiteq_enabled" @click="showNuiteq"
          ><img
            class="icon mr-2"
            :alt="$t('MESSAGES.TYPE.nuiteq-meeting')"
            src="/img/message_type/nuiteq-meeting.png"
          />
          {{ $t("MESSAGES.TYPE.nuiteq-meeting") }}</b-dropdown-item
        >
        <b-dropdown-item href="#" v-if="teams_enabled" @click="showTeams"
          ><img
            class="icon mr-2"
            :alt="$t('MESSAGES.TYPE.teams-meeting')"
            src="/img/message_type/teams-meeting.png"
          />
          {{ $t("MESSAGES.TYPE.teams-meeting") }}</b-dropdown-item
        >
      </b-dropdown>
    </span>
    <span :key="'AddMeeting_' + showIndex">
      <JitsiMeeting
        :message_uuid="message_uuid"
        v-if="jitsi_enabled"
        :show="showJitsiMeeting"
        @ChangeShow="ChangeShowJitsiMeeting"
        @SentMeeting="SentMeeting"
      ></JitsiMeeting>
      <JitsiConfidentiallyMeeting
        :message_uuid="message_uuid"
        v-if="jitsi_confidential_enabled"
        :show="showJitsiConfidentiallyMeeting"
        @ChangeShow="ChangeShowJitsiConfidentiallyMeeting"
        @SentMeeting="SentMeeting"
      ></JitsiConfidentiallyMeeting>
      <NuiteqMeeting
        :message_uuid="message_uuid"
        v-if="nuiteq_enabled"
        :show="showNuiteqMeeting"
        @ChangeShow="ChangeShowNuiteqMeeting"
        @SentMeeting="SentMeeting"
      ></NuiteqMeeting>
      <TeamsMeeting
        :message_uuid="message_uuid"
        v-if="teams_enabled"
        :show="showTeamsMeeting"
        @ChangeShow="ChangeShowTeamsMeeting"
        @SentMeeting="SentMeeting"
      ></TeamsMeeting>
    </span>
  </span>
</template>
<script>
import JitsiMeeting from "./Meeting/JitsiMeeting";
import JitsiConfidentiallyMeeting from "./Meeting/JitsiConfidentiallyMeeting";
import NuiteqMeeting from "./Meeting/NuiteqMeeting";
import TeamsMeeting from "./Meeting/TeamsMeeting";
export default {
  props: {
    draftInformation: {
      type: Object,
      default: null,
    },
  },
  components: {
    NuiteqMeeting,
    JitsiMeeting,
    JitsiConfidentiallyMeeting,
    TeamsMeeting,
  },
  data() {
    return {
      showIndex: 0,
      showNuiteqMeeting: false,
      showTeamsMeeting: false,
      showJitsiMeeting: false,
      showJitsiConfidentiallyMeeting: false,
      nuiteq_enabled: false,
      teams_enabled: false,
      jitsi_enabled: false,
      jitsi_confidential_enabled: false,
    };
  },
  methods: {
    async checkEnabled() {
      try {
        let result = await this.$http.get(
          this.user.hostname + "/teams/enabled"
        );
        this.teams_enabled = result.data;
      } catch {
        console.log("Could not fetch nuiteq enabled");
      }
      try {
        let result = await this.$http.get(
          this.user.hostname + "/nuiteq/enabled"
        );
        this.nuiteq_enabled = result.data;
      } catch {
        console.log("Could not fetch nuiteq enabled");
      }
      try {
        let result = await this.$http.get(
          this.user.hostname + "/jitsi/enabled"
        );
        this.jitsi_enabled = result.data;
      } catch {
        console.log("Could not fetch jitsi enabled");
      }
      try {
        let result = await this.$http.get(
          this.user.hostname + "/jitsi/confidentially/enabled"
        );
        this.jitsi_confidential_enabled = result.data;
      } catch {
        console.log("Could not fetch jitsi confidentially enabled");
      }
    },
    ChangeShowTeamsMeeting(val) {
      this.showIndex++;
      this.showTeamsMeeting = val;
      this.$emit("Close");
    },
    ChangeShowNuiteqMeeting(val) {
      this.showIndex++;
      this.showNuiteqMeeting = val;
      this.$emit("Close");
    },
    ChangeShowJitsiMeeting(val) {
      this.showIndex++;
      this.showJitsiMeeting = val;
      this.$emit("Close");
    },
    ChangeShowJitsiConfidentiallyMeeting(val) {
      this.showIndex++;
      this.showJitsiConfidentiallyMeeting = val;
      this.$emit("Close");
    },
    showJitsi() {
      this.showJitsiMeeting = true;
    },
    showJitsiConfidentially() {
      this.showJitsiConfidentiallyMeeting = true;
    },
    showNuiteq() {
      this.showNuiteqMeeting = true;
    },
    showTeams() {
      this.showTeamsMeeting = true;
    },
    SentMeeting() {
      this.showIndex++;
      return this.$emit("Changed");
    },
  },
  computed: {
    message_uuid() {
      return this.draftInformation ? this.draftInformation.messageUuid : "";
    },
    meetingEnabled() {
      return (
        this.teams_enabled ||
        this.jitsi_confidential_enabled ||
        this.jitsi_enabled ||
        this.nuiteq_enabled
      );
    },
    showMultiple() {
      return true;
    },
    composeText() {
      return this.$t("MESSAGES.COMPOSE") + " - SEFOS";
    },
  },
  watch: {
    draftInformation: function (draftInformation) {
      if (draftInformation == null) {
        return;
      }
      switch (draftInformation.location) {
        case "jitsi-secure-meeting":
          this.showJitsiConfidentially();
          break;
        case "teams-meeting":
          this.showTeams();
          break;
        case "jitsi-meeting":
          this.showJitsi();
          break;
        case "nuiteq-meeting":
          this.showNuiteq();
          break;
        default:
          break;
      }
    },
  },
  mounted() {
    this.checkEnabled();
  },
};
</script>
