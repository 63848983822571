<template>
  <section v-if="user.information && loaded">
    <div v-if="!meeting_enabled">
      <div class="center-information">
        <div class="center-information-content">
          <i class="fa-light fa-2x fa-video-slash"></i>
          <br /><br />
          {{ $t("NO_MEETING_SERVICE") }}
        </div>
      </div>
    </div>

    <div v-show="message_uuid == ''" v-if="meeting_enabled">
      <b-form inline class="mt-2">
        <AddressBook
          user_uuid=""
          functionbox_uuid=""
          v-slot:activator="{ onClick }"
        >
          <b-button
            @click="onClick"
            class="no-border"
            v-b-tooltip.hover
            :title="$t('ADDRESSBOOK.TITLE')"
          >
            <i class="fal fa-address-book"></i
          ></b-button>
        </AddressBook>

        <b-button @click="refreshList" class="no-border"
          ><i class="fal fa-sync"></i
        ></b-button>

        <AddMeeting
          @Close="CloseAddMeeting"
          :key="addMeetingKey"
          @Changed="Changed"
          :draftInformation="draftInformation"
        ></AddMeeting>

        <label class="sr-only" for="search-meetings">{{ $t("SEARCH") }}</label>
        <b-form-input
          trim
          id="search-meetings"
          class="ml-2 i-100 hidden-mobile searchText ml-2"
          v-model="searchText"
          debounce="500"
          :placeholder="$t('SEARCH')"
          @keydown.enter.prevent
        ></b-form-input>
        <label class="sr-only" for="list-limit-meetings">{{
          $t("LIMIT")
        }}</label>
        <b-form-select
          id="list-limit-meetings"
          name="meetings_limit"
          v-model="limit"
          class="limit hidden-mobile"
        >
          <b-form-select-option value="10">10</b-form-select-option>
          <b-form-select-option value="25">25</b-form-select-option>
          <b-form-select-option value="45">45</b-form-select-option>
          <b-form-select-option value="100">100</b-form-select-option>
        </b-form-select>

        <div class="hidden-not-mobile w-100 mt-2">
          <b-form inline>
            <label class="sr-only" for="search-meetings-mobile">{{
              $t("SEARCH")
            }}</label>
            <b-form-input
              trim
              id="search-meetings-mobile"
              class="ml-2 i-100 searchText"
              v-model="searchText"
              :placeholder="$t('SEARCH')"
              @keydown.enter.prevent
            ></b-form-input>
            <label class="sr-only" for="inline-form-input-limit-mobile">{{
              $t("LIMIT")
            }}</label>
            <b-form-select
              id="inline-form-input-limit-mobile"
              name="meetings_limit_mobile"
              v-model="limit"
              class="limit"
            >
              <b-form-select-option value="10">10</b-form-select-option>
              <b-form-select-option value="25">25</b-form-select-option>
              <b-form-select-option value="45">45</b-form-select-option>
              <b-form-select-option value="100">100</b-form-select-option>
            </b-form-select>
          </b-form>
        </div>
      </b-form>

      <b-nav tabs>
        <b-nav-item
          :to="{
            name: 'Meetings.Calendar',
            params: { searchText: searchText },
          }"
          exact
          >{{ $t("MEETINGS.CALENDAR") }}</b-nav-item
        >

        <b-nav-item :to="{ name: 'Meetings.Drafts' }" exact>
          {{ $t("MESSAGES.DRAFTS") }}
        </b-nav-item>
      </b-nav>

      <router-view
        :refresh="'router_Meetings' + keyIndex"
        :searchText.sync="searchText"
        @changeUpdateMessageUuid="changeUpdateMessageUuid"
        :update_message_uuid="update_message_uuid"
        @viewingMessage="viewingMessage"
        @draftMeeting="DraftMeeting"
        :limit.sync="limit"
      ></router-view>
      
    </div>
    <div v-if="message_uuid != ''">
      <Message
        :message_uuid="message_uuid"
        :startMeeting="startMeeting"
        :endMeeting="endMeeting"
        functionbox_uuid=""
        show-close-btn
        @closeMessage="closeMessage"
      ></Message>
    </div>
  </section>
</template>
<script>
import AddressBook from "@/components/AddressBook/Page";
import Message from "@/components/View/Message";
import AddMeeting from "@/components/Compose/AddMeeting";
export default {
  components: { Message, AddMeeting, AddressBook },
  props: ["functionbox_uuid", "email", "view_message_uuid"],
  data() {
    return {
      startMeeting: null,
      endMeeting: null,
      draftInformation: null,
      update_message_uuid: "",
      viewMessage: false,
      searchText: "",
      limit: 45,
      information: null,
      message_uuid: this.view_message_uuid,
      keyIndex: 0,
      meeting_enabled: true,
      addMeetingKeyIndex: 0,
      loaded: false,
      selectedTab: "calendar",
    };
  },
  methods: {
    DraftMeeting(draftInformation) {
      this.draftInformation = draftInformation;      
      this.message_uuid = "";
      this.update_message_uuid = "";
      this.startMeeting = null;
      this.endMeeting = null;
    },
    async checkEnabled() {
      let self = this;
      await this.$http
        .get(this.user.hostname + "/meeting/enabled")
        .then(function (result) {
          self.meeting_enabled = result.data;
          self.loaded = true;
        })
        .catch(function () {
          self.meeting_enabled = false;
          self.loaded = true;
        });
    },
    changedView(view) {
      this.currentView = view;
    },
    changeUpdateMessageUuid(update_message_uuid) {
      this.update_message_uuid = update_message_uuid;
      this.message_uuid = "";
      this.draftInformation = {        
        messageUuid: '',
        location: ''
      };
      this.startMeeting = null;
      this.endMeeting = null;
    },
    refreshList() {      
      this.keyIndex++;
    },
    Changed() {      
      this.$router.push({ name: "Meetings.Calendar" }).catch(() => {});
      this.draftInformation = null;
      this.refreshList();
    },
    CloseAddMeeting(){
      this.draftInformation = null;
      this.refreshList();
    },
    viewingMessage(item) {
      this.message_uuid = item.MessageUuid;
      this.draftInformation = null;
      this.update_message_uuid = "";
      if(item.Recurrence != null)
      {
        this.startMeeting = item.StartTime;
        this.endMeeting = item.EndTime;
      }else{
        this.startMeeting = null;
        this.endMeeting = null;
      }
    },
    closeMessage() {
      this.update_message_uuid = this.message_uuid;
      this.message_uuid = "";
      this.draftInformation = null;
      this.refreshList();
      this.startMeeting = null;
      this.endMeeting = null;
    },
    selectTab(tab) {
      this.selectedTab = tab;
      this.refreshList();
    },
  },
  computed: {
    listMeetingKey() {
      return "listMeetingKey_" + this.keyIndex;
    },
    addMeetingKey() {
      return "addMeetingKey_" + this.keyIndex;
    },
  },
  mounted() {
    this.checkEnabled();
  },
};
</script>
<style></style>
